import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronsRight } from '@fortawesome/pro-duotone-svg-icons';

import AppContext from '../../helpers/AppContext';

const Services = () => {
	const { t } = useTranslation();
	const { getLink, getIcon, getIconClass, services } = useContext(AppContext);

	const item = what => (
		<Col key={'home-' + what} xs="12" sm="6" lg="4" className="d-flex flex-column text-center">
			<Link to={getLink(what)}>
				<FontAwesomeIcon icon={getIcon(what)} className={'display-3 mb-4 ' + getIconClass(what)} />
			</Link>
			<h4>
				<Link to={getLink(what)}>
					{t('home.' + what)} <FontAwesomeIcon icon={faChevronsRight} className="duo-primary-swap home-services-icon" />
				</Link>
			</h4>
		</Col>
	);

	return (
		<section id="services" className="section-content dark drop-back">
			<Container flud="lg" className="section-inner">
				<div className="mb-5">
					<h2 className="bordered-title display-5">{t('home.services')}</h2>
					<p>{t('home.servicesText')}</p>
				</div>
				<Row className="align-items-start g-5 ">{services.map(service => item(service))}</Row>
			</Container>
		</section>
	);
};

export default Services;
