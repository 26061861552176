import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppContext from '../helpers/AppContext';
import useGaTracker from '../helpers/useGaTracker';

import Header from '../components/header/Header';
import ErrorPage from '../components/extra/ErrorPage';
import Footer from '../components/Footer';

import Home from './Home';
import Company from './Company';
import Services from './Services';
import Composer from './Composer';
import Cloud from './Cloud';
import Privacy from './Privacy';
import Redirect from './Redirect';

const Index = ({ lang }) => {
	const { getUrl } = useContext(AppContext);

	useGaTracker();

	return (
		<div className="main">
			<Header />
			<div className="content">
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/index.html" element={<Home />} />
					<Route exact path="/index" element={<Home />} />

					<Route path={getUrl('company')} element={<Company />} />

					<Route path={getUrl('web')} element={<Services what="web" />} />
					<Route path={getUrl('dt')} element={<Services what="dt" />} />
					<Route path={getUrl('bpr')} element={<Services what="bpr" />} />
					<Route path={getUrl('ai')} element={<Services what="ai" />} />
					<Route path={getUrl('3d')} element={<Services what="3d" />} />
					<Route path={getUrl('i4')} element={<Services what="i4" />} />
					<Route path={getUrl('blockchain')} element={<Services what="blockchain" />} />
					<Route path={getUrl('ar')} element={<Services what="ar" />} />

					<Route path={getUrl('composer')} element={<Composer />} />
					<Route path={getUrl('cloud')} element={<Cloud />} />

					<Route exact path="/privacy" element={<Privacy />} />

					<Route path="company-profile" element={<Redirect url={'/company-profile-' + lang + '.pdf'} />} />

					<Route path="*" element={<ErrorPage status="404" />} />
				</Routes>
				<Footer />
			</div>
		</div>
	);
};

export default Index;
