/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import anidride from '../../img/partnership/anidride-design.png';
import idf from '../../img/partnership/italian-design-farm.png';
import adv from '../../img/partnership/adv.png';

import sign2 from '../../img/signs/sign2.svg';

const Services = () => {
	const { t } = useTranslation();

	return (
		<section className="partnership-box">
			<div className="section-content light">
				<Container flud="lg" className="section-inner">
					<h2 className="bordered-title display-5">{t('home.partnership')}</h2>
					<img src={sign2} alt="" className="sign" />
				</Container>
			</div>
			<div className="section-content white">
				<Container flud="lg" className="section-inner">
					<div className="partnership-logos">
						<div>
							<a href="https://www.italiandesign.farm" target="_blank">
								<img src={idf} alt="Italian Design Farm" className="partnership-img" />
							</a>
						</div>
						<div>
							<a href="https://www.anidridedesign.com" target="_blank">
								<img src={anidride} alt="Anidride Design" className="partnership-img" />
							</a>
						</div>
						<div>
							<a href="https://www.advmedialab.com" target="_blank">
								<img src={adv} alt="ADV media lab" className="partnership-img" />
							</a>
						</div>
					</div>
				</Container>
			</div>
		</section>
	);
};

export default Services;
