import React from 'react';
import { Spinner } from 'react-bootstrap';

const FullpageLoading = () => (
	<div className="fullpage d-flex align-items-center flex-column justify-content-center gap-5">
		<div className="logo bg-cover" />
		<Spinner animation="grow" variant="primary">
			<span className="visually-hidden">Loading...</span>
		</Spinner>
	</div>
);

export default FullpageLoading;
