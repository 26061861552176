/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';

const Privacy = () => {
	const { t, i18n } = useTranslation();
	const { setCurrentPage } = useContext(AppContext);

	const Text = require(`../locales/${i18n.language}/privacy`).default;

	useEffect(() => {
		setCurrentPage('privacy');
	}, []);

	return (
		<>
			<MetaTags title="Privacy Policy" noFollow />
			<Container fluid="lg" className="privacy-page">
				<h1>{t('footer.privacy')}</h1>
				<Text />
			</Container>
		</>
	);
};

export default Privacy;
