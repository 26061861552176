import React, { useContext } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppContext from '../../helpers/AppContext';
import TextUnderline from '../extra/TextUnderline';

import sign1 from '../../img/signs/sign1.svg';
import sign2 from '../../img/signs/sign2.svg';
import sign3 from '../../img/signs/sign3.svg';
import sign4 from '../../img/signs/sign4.svg';

const Services = () => {
	const { t } = useTranslation();
	const { getLink } = useContext(AppContext);

	const scrollWithOffset = el => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -64;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
	};

	return (
		<section className="section-content drop-front">
			<Container flud="lg" className="section-inner sm">
				<Row className="justify-content-center g-4">
					<Col xs="12" className="d-grid gap-2">
						<h2 className="bordered-title display-5">
							<TextUnderline text={t('home.who')} />
						</h2>
						<p>{t('home.who1')}</p>
					</Col>
					<Col xs="12" md="6">
						<h3>
							1//
							<br />
							{t('home.who2')}
						</h3>
					</Col>
					<Col xs="12" md="6">
						<h3>
							2//
							<br />
							{t('home.who3')}
						</h3>
					</Col>
					<Col xs="12">
						<p>
							<Link to={getLink('company')}>{t('common.more')}</Link>
						</p>
					</Col>
				</Row>
				<div id="home-company" className="content-image bg-cover">
					<div className="gradient" />
				</div>
				<div className="home-why text-center">
					<img src={sign1} alt="" className="signs sign1" />
					<img src={sign2} alt="" className="signs sign2" />
					<img src={sign3} alt="" className="signs sign3" />
					<img src={sign4} alt="" className="signs sign4" />
					<h3 className="display-6">{t('home.why')}</h3>
					<Carousel>
						<Carousel.Item interval={3000}>
							<div className="d-block w-100">
								<div className="carousel-item-inner">
									<h4 className="display-6">
										1//
										<br />
										{t('home.why1')}
									</h4>
								</div>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<div className="d-block w-100">
								<div className="carousel-item-inner">
									<h4 className="display-6 fw-bold">
										2//
										<br />
										{t('home.why2')}
									</h4>
								</div>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<div className="d-block w-100">
								<div className="carousel-item-inner">
									<h4 className="display-6 fw-bold">
										3//
										<br />
										{t('home.why3')}
									</h4>
								</div>
							</div>
						</Carousel.Item>
					</Carousel>
					<p className="mt-5 mb-0">
						<HashLink to={getLink('company') + '#why'} scroll={el => scrollWithOffset(el)}>
							{t('common.more')}
						</HashLink>
					</p>
				</div>
			</Container>
		</section>
	);
};

export default Services;
