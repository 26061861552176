import React, { useState, useContext, useLayoutEffect, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { gsap } from 'gsap';
import { Spin as Hamburger } from 'hamburger-react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

import Menu from './Menu';
import AppContext from '../../helpers/AppContext';

const Header = () => {
	const { isMenuOpen, setIsMenuOpen, currentPage, getUrl } = useContext(AppContext);
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const [disabled, setDisabled] = useState(false);
	const [logoLastScale, setLogoLastScale] = useState(2);

	const contacts = useRef();
	const langs = useRef();
	const icon = useRef();
	const hamburger = useRef();
	const topBack = useRef();

	const logoMaxScale = () => (window.innerWidth > 1280 ? 3 : 2);

	const disableMenu = () => {
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, 800);
	};

	const handleClick = () => {
		disableMenu();
		gsap.to(icon.current, {
			scale: !isMenuOpen ? logoMaxScale() : logoLastScale,
			transformOrigin: 'top left',
			delay: 0.5
		});
		setIsMenuOpen(!isMenuOpen);
	};

	const onScroll = event => {
		const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		const targetValue = window.innerHeight / 3;
		const percent = (scrollTop * 100) / targetValue / 100;
		const scale = scrollTop > targetValue ? 1 : logoMaxScale() - percent;

		gsap.to(icon.current, { scale, transformOrigin: 'top left' });
		gsap.to(topBack.current, { opacity: percent < 1 ? percent : 1 });

		setLogoLastScale(scale);
	};

	useLayoutEffect(() => {
		window.addEventListener('scroll', onScroll);
		onScroll();

		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	useEffect(() => {
		if (isMenuOpen) handleClick();
		if (location.hash) return;

		window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
	}, [location]);

	useEffect(() => {
		const animTime = 0.4;
		if (!isMenuOpen) {
			gsap
				.timeline()
				.to(langs.current, { duration: animTime, opacity: '0' })
				.to(langs.current, { duration: 0, display: 'none' })
				.to(contacts.current, { duration: animTime, display: 'block' })
				.to(contacts.current, { duration: 0, opacity: '1' });
		} else {
			gsap
				.timeline()
				.to(contacts.current, { duration: animTime, opacity: '0' })
				.to(contacts.current, { duration: 0, display: 'none' })
				.to(langs.current, { duration: 0, display: 'block' })
				.to(langs.current, { duration: animTime, opacity: 1 });
		}

		document.body.className = isMenuOpen ? 'menuOpen' : '';
	}, [isMenuOpen]);

	const changeLang = (e, lang) => {
		e.preventDefault();
		i18n.changeLanguage(lang).then(() => {
			window.location.replace('/' + lang + '/' + getUrl(currentPage));
		});
	};

	return (
		<header>
			<div className="topbar">
				<Container fluid="lg" className="d-flex align-items-center justify-content-between ">
					<Link ref={icon} to="/" className="logo bg-cover" />
					<div className="d-flex align-items-center">
						<div ref={contacts} className="topbar-contacts me-3">
							<FontAwesomeIcon icon={faPaperPlane} className="duo-primary-swap me-2" />
							<HashLink to="#contact-us">{t('menu.contacts')}</HashLink>
						</div>
						<div ref={langs} className="topbar-langs me-2">
							<Link to onClick={e => changeLang(e, 'it')}>
								IT
							</Link>
							<Link to onClick={e => changeLang(e, 'en')}>
								EN
							</Link>
						</div>
						<button
							ref={hamburger}
							className={'hamburger-button' + (!isMenuOpen && !disabled ? ' close' : '')}
							type="button"
							disabled={disabled}
							onClick={handleClick}
						>
							<Hamburger toggled={isMenuOpen} size={20} label="menu" />
						</button>
					</div>
				</Container>
			</div>
			<div ref={topBack} className="topbar-background" />
			<Menu />
		</header>
	);
};

export default Header;
