/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CaseStudy = ({ cases }) => {
	const { t } = useTranslation();

	const item = e => (
		<div key={e.key} className="d-flex flex-column gap-3 align-items-center">
			<a href={e.link} target="_blank">
				<div id={'case-' + e.key} className="case-study-logo bg-cover" />
			</a>
			<a href={e.link} target="_blank">
				{e.text}
			</a>
		</div>
	);

	return (
		<section className="section-content dark">
			<Container flud="lg" className="section-inner">
				<div className="d-flex flex-column gap-3 align-items-center text-center">
					<h2>{t('solutions.case')}</h2>
					<div className="d-flex gap-5 align-items-center">{cases.map(e => item(e))}</div>
				</div>
			</Container>
		</section>
	);
};

export default CaseStudy;
