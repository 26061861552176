import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import ReactPlayer from 'react-player';

import AppContext from '../../helpers/AppContext';

import composer from '../../img/solutions/composer.mp4';
import cloudDesktop from '../../img/solutions/cloud-desktop.jpg';
import cloudMobile from '../../img/solutions/cloud-mobile.jpg';

const Services = () => {
	const { t } = useTranslation();
	const { getLink } = useContext(AppContext);
	const [playState, updatePlayState] = useState(false);

	return (
		<section className="section-content">
			<Container flud="lg" className="section-inner">
				<div className="mb-5">
					<h2 className="bordered-title display-5">{t('home.solutions')}</h2>
				</div>
				<Row className="g-5 d-flex align-items-center">
					<Col xs="12" md="6" className="d-flex flex-column order-xs-0 order-md-0">
						<div className="solution-item">
							<div className="composer solution-bg" />
							<div className="composer solution-image-box">
								<Waypoint onEnter={() => updatePlayState(true)} onLeave={() => updatePlayState(false)}>
									<div className="browser">
										<div className="video-wrapper bg-cover">
											<ReactPlayer
												playing={playState}
												url={composer}
												muted
												loop
												playsinline
												width="100%"
												height="100%"
											/>
										</div>
									</div>
								</Waypoint>
							</div>
						</div>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column order-xs-1 order-md-1">
						<h3 className="display-5">{t('composer.title')}</h3>
						<p>{t('composer.metaDesc')}</p>
						<p>
							<Link to={getLink('composer')}>{t('common.more')}</Link>
						</p>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column order-xs-2 order-md-3">
						<div className="solution-item">
							<div className="cloud solution-bg" />
							<div className="cloud solution-image-box">
								<div className="device-twin">
									<div className="browser">
										<img src={cloudDesktop} alt="MEBcloud browser" />
									</div>
									<div className="cloud-mobile">
										<div className="front iphone light">
											<div className="screen">
												<img src={cloudMobile} alt="MEBcloud smartphone" />
											</div>
											<div className="notch" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column order-xs-3 order-md-2">
						<h3 className="display-5">{t('cloud.title')}</h3>
						<p>{t('cloud.metaDesc')}</p>
						<p>
							<Link to={getLink('cloud')}>{t('common.more')}</Link>
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Services;
