import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import { AppProvider } from './helpers/AppContext';
import FullpageLoading from './components/extra/FullpageLoading';

import Main from './Main';

import './helpers/i18n';
import './sass/style.scss';

ReactDOM.render(
	<Suspense fallback={<FullpageLoading />}>
		<AppProvider>
			<BrowserRouter>
				<Main />
			</BrowserRouter>
		</AppProvider>
	</Suspense>,
	document.getElementById('root')
);
