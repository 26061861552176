import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Index from './routes';
import Redirect from './routes/Redirect';

const Main = () => {
	const { i18n } = useTranslation();
	const { language } = i18n;

	return (
		<Routes>
			<Route path="en/*" element={<Index lang="en" />} />
			<Route path="it/*" element={<Index lang="it" />} />
			<Route path="company-privacy" element={<Redirect url="privacy.pdf" />} />
			<Route path="*" element={<Navigate to={language} replace />} />
		</Routes>
	);
};

export default Main;
