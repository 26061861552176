/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useLayoutEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
	faSensorCloud,
	faRocketLaunch,
	faDiceD6,
	faBrainCircuit,
	faMicrochip,
	faStarfighter,
	faDiceD8,
	faGlasses
} from '@fortawesome/pro-duotone-svg-icons';

const AppContext = React.createContext({});
export default AppContext;

export const AppProvider = props => {
	const [isMobile, setIsMobile] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState('');

	const { t, i18n } = useTranslation();
	const { language } = i18n;

	useLayoutEffect(() => {
		const updateSize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', updateSize);
		updateSize();

		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const getUrl = what => {
		const escape = s =>
			s
				.replace(/ /g, '-')
				.normalize('NFD')
				.replace(/([\u0300-\u036f]|[^0-9a-zA-Z-])/g, '')
				.replace(/--/g, '-')
				.toLowerCase();

		switch (what) {
			case 'company':
				return escape(t('menu.company'));
			case 'web':
				return escape(t('menu.services')) + '/' + escape(t('menu.web'));
			case 'dt':
				return escape(t('menu.services')) + '/' + escape(t('menu.dt'));
			case 'bpr':
				return escape(t('menu.services')) + '/' + escape(t('menu.bpr'));
			case 'ai':
				return escape(t('menu.services')) + '/' + escape(t('menu.ai'));
			case '3d':
				return escape(t('menu.services')) + '/' + escape(t('menu.3d'));
			case 'i4':
				return escape(t('menu.services')) + '/' + escape(t('menu.i4'));
			case 'blockchain':
				return escape(t('menu.services')) + '/' + escape(t('menu.blockchain'));
			case 'ar':
				return escape(t('menu.services')) + '/' + escape(t('menu.ar'));
			case 'composer':
				return escape(t('menu.solutions')) + '/' + escape(t('menu.composer'));
			case 'cloud':
				return escape(t('menu.solutions')) + '/' + escape(t('menu.cloud'));
			case 'privacy':
				return 'privacy';
			default:
				return '';
		}
	};

	const getLink = what => '/' + language + '/' + getUrl(what);

	const getIcon = what => {
		switch (what) {
			case 'web':
				return faSensorCloud;
			case 'dt':
				return faStarfighter;
			case 'bpr':
				return faRocketLaunch;
			case 'ai':
				return faBrainCircuit;
			case '3d':
				return faDiceD6;
			case 'i4':
				return faMicrochip;
			case 'blockchain':
				return faDiceD8;
			case 'ar':
				return faGlasses;
			default:
				return null;
		}
	};

	const getIconClass = what => {
		if (what === 'ar') return 'duo-primary';
		return 'duo-primary-swap';
	};

	const services = ['web', 'dt', 'bpr', 'ai', '3d', 'i4', 'blockchain', 'ar'];

	const nextService = s => {
		const i = services.indexOf(s);
		if (i === services.length - 1) return services[0];
		return services[i + 1];
	};

	const previousService = s => {
		const i = services.indexOf(s);
		if (i === 0) return services[services.length - 1];
		return services[i - 1];
	};

	return (
		<AppContext.Provider
			value={{
				isMobile,
				isMenuOpen,
				setIsMenuOpen,
				getUrl,
				getLink,
				getIcon,
				getIconClass,
				services,
				nextService,
				previousService,
				currentPage,
				setCurrentPage
			}}
		>
			<Helmet htmlAttributes={{ lang: language }} />
			{props.children}
		</AppContext.Provider>
	);
};
