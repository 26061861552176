/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

import sign4 from '../../img/signs/sign4.svg';

const Why = () => {
	const { t } = useTranslation();

	return (
		<>
			<section id="why" className="why-box">
				<div className="section-content light">
					<Container flud="lg" className="section-inner">
						<h2 className="bordered-title display-5">{t('company.why')}</h2>
						<p className="fs-4">{t('company.whyText')}</p>
						<img src={sign4} alt="" className="sign" />
					</Container>
				</div>
			</section>
			<section className="section-content">
				<Container flud="lg" className="section-inner">
					<Row className="g-5 d-flex align-items-center">
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-1 order-lg-0">
							<div id="company-image2" className="content-image square bg-cover" />
						</Col>
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-0 order-lg-1">
							<h3 className="fs-2">{t('company.why1')}</h3>
							<p>
								<Trans i18nKey="company.why1Text_1" />
							</p>
							<p>
								<Trans i18nKey="company.why1Text_2" />
							</p>
						</Col>
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-3 order-lg-3">
							<div id="company-image3" className="content-image square bg-cover" />
						</Col>
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-2 order-lg-2">
							<h3 className="fs-2">{t('company.why2')}</h3>
							<p>
								<Trans i18nKey="company.why2Text_1" />
							</p>
							<p>
								<Trans i18nKey="company.why2Text_2" />
							</p>
						</Col>
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-5 order-lg-4">
							<div id="company-image4" className="content-image square bg-cover" />
						</Col>
						<Col xs="12" lg="6" className="d-flex flex-column order-xs-4 order-lg-5">
							<h3 className="fs-2">{t('company.why3')}</h3>
							<p>
								<Trans i18nKey="company.why3Text_1" />
							</p>
							<p>
								<Trans i18nKey="company.why3Text_2" />
							</p>
							<p>
								<Trans i18nKey="company.why3Text_3" />
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default Why;
