/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/pro-duotone-svg-icons';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';
import TextUnderline from '../components/extra/TextUnderline';
import featuresIcons from '../components/services/FeaturesIcon';

const Services = ({ what }) => {
	const { t } = useTranslation();
	const { getIcon, getIconClass, getLink, nextService, previousService, setCurrentPage } = useContext(AppContext);
	const [overIcon, setOverIcon] = useState(false);

	const metaImg = require(`../img/services/${what}-s.jpg`);

	useEffect(() => {
		setCurrentPage(what);
	}, [what]);

	const feature = index => (
		<Col key={what + '-feature-' + index} xs="12" sm="6" className="d-flex flex-column text-center">
			<div className="features-col">
				<FontAwesomeIcon icon={featuresIcons[what][index]} className="duo-primary-swap display-3 mb-4" />
				<h3>{t(what + '.feature' + index)}</h3>
				<p>{t(what + '.feature' + index + '_text')}</p>
			</div>
		</Col>
	);

	const nextServiceValue = nextService(what);
	const previousServiceValue = previousService(what);

	return (
		<>
			<MetaTags title={t(what + '.title')} desc={t(what + '.metaDesc')} img={metaImg} />
			<section id="services-header" className="section-full bg-cover">
				<div className="section-inner">
					<div className="d-flex flex-column gap-3 align-items-center text-center">
						<FontAwesomeIcon icon={getIcon(what)} className={'display-1 ' + getIconClass(what)} />
						<h1 className="display-1">
							<TextUnderline text={t(what + '.title')} />
						</h1>
						<p className="big">{t(what + '.metaDesc')}</p>
					</div>
				</div>
			</section>
			<section className="section-content">
				<Container flud="lg" className="section-inner">
					<Row className="g-5 d-flex align-items-center">
						<Col xs="12" lg="6" className="d-flex flex-column order-lg-1">
							<div id={'image1-' + what} className="content-image square bg-cover" />
						</Col>
						<Col xs="12" lg="6" className=" align-self-stretch  order-lg-0">
							<div className="service-text-box d-flex flex-column justify-content-center">
								<p>
									<Trans i18nKey={what + '.text'} />
								</p>
							</div>
						</Col>
					</Row>
				</Container>
				<div id={'image2-' + what} className="content-image service-image-highlight bg-cover">
					<div className="gradient" />
					<div className="gradient-bottom" />
					<div className="content-image-text">
						<Container fluid="lg" className="section-inner sm">
							<p className="fs-4">
								<Trans i18nKey={what + '.highlight'} />
							</p>
						</Container>
					</div>
				</div>
			</section>
			<section id="services" className="section-content dark">
				<Container flud="lg" className="section-inner">
					<div className="mb-5">
						<h2 className="bordered-title display-5">{t(what + '.features')}</h2>
					</div>
					<Row className="align-items-start g-5">{[1, 2, 3, 4].map(index => feature(index))}</Row>
				</Container>
			</section>
			<section className="services-btn-box">
				<div className="section-content light">
					<Container flud="lg" className="section-inner">
						<Row>
							<Col xs="6" className="services-btn services-previous-btn">
								<Link
									to={getLink(previousServiceValue)}
									onMouseEnter={() => setOverIcon('previous')}
									onMouseLeave={() => setOverIcon(false)}
								>
									<h3>{t('menu.' + previousServiceValue)}</h3>
									<p>{t('common.service_prev')}</p>
									<FontAwesomeIcon
										icon={faArrowLeftLong}
										className={'display-5 ' + (overIcon === 'previous' ? 'fa-passing-left' : '')}
									/>
								</Link>
							</Col>
							<Col xs="6" className="services-btn services-next-btn">
								<Link
									to={getLink(nextServiceValue)}
									onMouseEnter={() => setOverIcon('next')}
									onMouseLeave={() => setOverIcon(false)}
								>
									<h3>{t('menu.' + nextServiceValue)}</h3>
									<p>{t('common.service_next')}</p>
									<FontAwesomeIcon
										icon={faArrowRightLong}
										className={'display-5 ' + (overIcon === 'next' ? 'fa-passing-right' : '')}
									/>
								</Link>
							</Col>
						</Row>
					</Container>
				</div>
			</section>
		</>
	);
};

export default Services;
