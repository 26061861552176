import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';
import TextUnderline from '../components/extra/TextUnderline';

import Content from '../components/solutions/Content';
import CaseStudy from '../components/solutions/CaseStudy';

import cloudDesktop from '../img/solutions/cloud-desktop.jpg';
import cloudMobile from '../img/solutions/cloud-mobile.jpg';

const Cloud = () => {
	const { t } = useTranslation();
	const { setCurrentPage } = useContext(AppContext);

	useEffect(() => {
		setCurrentPage('cloud');
	}, []);

	return (
		<>
			<MetaTags title={t('cloud.title')} desc={t('cloud.metaDesc')} img={cloudDesktop} />

			<section className="section-content section-solution">
				<Container flud="lg" className="section-inner">
					<Row className="g-md-5 d-flex align-items-center">
						<Col xs="12" md="6" className="d-flex flex-column">
							<div className="solution-item">
								<div className="cloud solution-bg" />
								<div className="cloud solution-image-box">
									<div className="device-twin">
										<div className="browser">
											<img src={cloudDesktop} alt="MEBcloud browser" />
										</div>
										<div className="cloud-mobile">
											<div className="front iphone light">
												<div className="screen">
													<img src={cloudMobile} alt="MEBcloud smartphone" />
												</div>
												<div className="notch" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs="12" md="6" className="d-flex flex-column">
							<h1 className="display-1">
								<TextUnderline text={t('cloud.title')} />
							</h1>
							<p>{t('cloud.metaDesc')}</p>
						</Col>
					</Row>
				</Container>
			</section>

			<Content what="cloud" />
			<CaseStudy
				cases={[
					{ key: 'blackfin', text: 'Blackfin', link: 'https://www.blackfin.eu' },
					{ key: 'lightbird', text: 'Lightbird', link: 'https://www.lightbird.it' }
				]}
			/>
		</>
	);
};

export default Cloud;
