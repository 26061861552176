import React from 'react';

const TextUnderline = ({ text }) => {
	const index = text.lastIndexOf('\n');
	const title = text.substring(0, index);
	const mark = text.split('\n').pop();

	return (
		<>
			{title}
			<br />
			<mark>{mark}</mark>
		</>
	);
};

export default TextUnderline;
