import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Redirect = ({ url }) => {
	useEffect(() => {
		window.location.href = url;
	}, []);

	return <Outlet />;
};

export default Redirect;
