import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useGaTracker = () => {
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
			ReactGA.initialize([
				{
					trackingId: process.env.REACT_APP_GA,
					gaOptions: {
						anonymizeIp: true
					}
				}
			]);

			setInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (initialized) {
			ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
		}
	}, [initialized, location]);
};

export default useGaTracker;
