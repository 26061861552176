import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';
import TextUnderline from '../components/extra/TextUnderline';

import Partner from '../components/company/Partner';
import Why from '../components/company/Why';

import metaImg from '../img/company/why2.jpg';

const Company = props => {
	const { t } = useTranslation();
	const { setCurrentPage } = useContext(AppContext);

	useEffect(() => {
		setCurrentPage('company');
	}, []);

	return (
		<>
			<MetaTags title={t('company.title')} desc={t('company.metaDesc')} img={metaImg} />
			<section id="company-header" className="section-full bg-cover dark">
				<div className="section-inner">
					<div className="d-flex flex-column gap-3 align-items-center text-center">
						<h1 className="display-1">
							<TextUnderline text={t('company.title')} />
						</h1>
						<p className="big">{t('company.metaDesc')}</p>
					</div>
				</div>
			</section>
			<Partner />
			<Why />
		</>
	);
};

export default Company;
