import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceThinking } from '@fortawesome/pro-duotone-svg-icons';

import MetaTags from './MetaTags';

const ErrorPage = props => {
	const { t } = useTranslation();
	const status = props.status ? props.status : '404';

	return (
		<>
			<MetaTags title={t(status + '.title')} noFollow />
			<section id="home-header" className="section-full bg-cover">
				<div className="section-inner">
					<div className="d-flex flex-column gap-3 align-items-center text-center">
						<FontAwesomeIcon icon={faFaceThinking} className="duo-primary display-1" />
						<div>
							<h1>{t(status + '.title')}</h1>
							<p>{t(status + '.text')}</p>
							<Link to="/">{t(status + '.link')}</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ErrorPage;
