import {
	faVault,
	faHandHoldingDollar,
	faJetFighter,
	faPersonRunning,
	faRabbitRunning,
	faChartLineUp,
	faCoins,
	faLayerGroup,
	faTelescope,
	faUserSecret,
	faChess,
	faCameraCctv,
	faUniversalAccess,
	faUnicorn,
	faBanBug,
	faFlagCheckered,
	faRobotAstromech,
	faChartUser,
	faCrystalBall,
	faRightLeft,
	faBoxCheck,
	faShieldHalved,
	faArrowsToDottedLine,
	faScrewdriverWrench,
	faRulerTriangle
} from '@fortawesome/pro-duotone-svg-icons';

const featuresIcons = {
	web: {
		1: faJetFighter,
		2: faHandHoldingDollar,
		3: faPersonRunning,
		4: faVault
	},
	dt: {
		1: faRabbitRunning,
		2: faChartLineUp,
		3: faCoins,
		4: faLayerGroup
	},
	bpr: {
		1: faTelescope,
		2: faUserSecret,
		3: faChess,
		4: faCameraCctv
	},
	ai: {
		1: faRobotAstromech,
		2: faBanBug,
		3: faCoins,
		4: faUnicorn
	},
	'3d': {
		1: faUniversalAccess,
		2: faCoins,
		3: faRabbitRunning,
		4: faFlagCheckered
	},
	i4: {
		1: faChartUser,
		2: faPersonRunning,
		3: faCoins,
		4: faCrystalBall
	},
	blockchain: {
		1: faRightLeft,
		2: faBoxCheck,
		3: faArrowsToDottedLine,
		4: faShieldHalved
	},
	ar: {
		1: faUniversalAccess,
		2: faCoins,
		3: faScrewdriverWrench,
		4: faRulerTriangle
	}
};

export default featuresIcons;
