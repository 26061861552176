/* eslint-disable no-return-assign */
import React, { useRef, useEffect, useContext, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Container, Row, Col, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPhone, faAngleRight, faMinus } from '@fortawesome/pro-duotone-svg-icons';

import { staggerText, staggerReveal, staggerRevealClose, fadeInUp } from './Animations';
import AppContext from '../../helpers/AppContext';

const Menu = () => {
	const { isMenuOpen, getLink } = useContext(AppContext);
	const { t } = useTranslation();
	const [subMenu, setSubMenu] = useState(false);
	const location = useLocation();

	// Create varibles of our dom nodes
	const menuLayer = useRef();
	const reveal1 = useRef();
	const reveal2 = useRef();
	const nav = useRef();
	const info = useRef();
	const q = gsap.utils.selector(nav);

	useEffect(() => {
		if (!isMenuOpen) {
			enableBodyScroll(reveal2.current);
			staggerRevealClose(reveal2.current, reveal1.current);
			setSubMenu(false);

			gsap.to(menuLayer.current, { duration: 1, css: { display: 'none' } });
		} else {
			disableBodyScroll(reveal2.current);
			gsap.to(menuLayer.current, { duration: 0, css: { display: 'block' } });
			gsap.to([reveal1.current, reveal2.current], {
				duration: 0,
				opacity: 1,
				height: '100%'
			});

			staggerReveal(reveal1.current, reveal2.current);
			fadeInUp(info.current);
			staggerText(q('.menu-link'));
		}
	}, [isMenuOpen]);

	const subMenuHandler = e => {
		e.preventDefault();
		const value = e.target.getAttribute('data-menu');
		setSubMenu(subMenu === value ? false : value);
	};

	return (
		<div ref={menuLayer} className="menu">
			<div ref={reveal1} className="menu-secondary-background-color" />
			<div ref={reveal2} className="menu-layer">
				<div className="wrapper">
					<Container fluid="lg">
						<Row>
							<Col xs="12" md="8">
								<div className="menu-links">
									<nav ref={nav}>
										<ul>
											<li>
												<Link className="menu-link" to={getLink('company')}>
													{t('menu.company')}
												</Link>
											</li>
											<li>
												<Link className="menu-link" to onClick={e => subMenuHandler(e)} data-menu="services-menu">
													{t('menu.services')}{' '}
													<FontAwesomeIcon
														icon={faAngleRight}
														size="xs"
														rotation={subMenu === 'services-menu' ? 90 : 0}
													/>
												</Link>
											</li>
											<Collapse in={subMenu === 'services-menu'}>
												<div>
													<li className="menu-sub-item">
														<Link to={getLink('web')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.web')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('dt')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.dt')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('bpr')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.bpr')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('ai')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.ai')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('3d')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.3d')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('i4')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.i4')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('blockchain')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.blockchain')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('ar')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.ar')}
														</Link>
													</li>
												</div>
											</Collapse>
											<li>
												<Link className="menu-link" to onClick={e => subMenuHandler(e)} data-menu="solutions-menu">
													{t('menu.solutions')}{' '}
													<FontAwesomeIcon
														icon={faAngleRight}
														size="xs"
														rotation={subMenu === 'solutions-menu' ? 90 : 0}
													/>
												</Link>
											</li>
											<Collapse in={subMenu === 'solutions-menu'}>
												<div>
													<li className="menu-sub-item">
														<Link to={getLink('composer')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.composer')}
														</Link>
													</li>
													<li className="menu-sub-item">
														<Link to={getLink('cloud')}>
															<FontAwesomeIcon icon={faMinus} size="xs" fixedWidth /> {t('menu.cloud')}
														</Link>
													</li>
												</div>
											</Collapse>
											<li>
												<HashLink className="menu-link" to={location.pathname + '#contact-us'}>
													{t('menu.contacts')}
												</HashLink>
											</li>
										</ul>
									</nav>
								</div>
							</Col>
							<Col xs="12" md="4" className="menu-info">
								<div ref={info}>
									<h3>MEBlabs</h3>
									<p>Tailor Made Technologies</p>
									<p>
										Via Monte Catria, 6<br />
										61032 Fano (PU), Italy
										<br />
										P.IVA 02619960418
										<br />
										REA PS 196324
									</p>
									<p>
										<FontAwesomeIcon icon={faAt} fixedWidth /> <a href="mailto:staff@meblabs.com">staff@meblabs.com</a>
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</div>
	);
};

export default Menu;
