import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';
import TextUnderline from '../components/extra/TextUnderline';

import Company from '../components/home/Company';
import Services from '../components/home/Services';
import Solutions from '../components/home/Solutions';
import Partnership from '../components/home/Partnership';

import metaImag from '../img/meblabs.jpg';

const Home = () => {
	const { t } = useTranslation();
	const { getLink, setCurrentPage } = useContext(AppContext);

	useEffect(() => {
		setCurrentPage('home');
	}, []);

	return (
		<>
			<MetaTags title="Tailor Made Technologies" desc={t('home.metaDesc')} img={metaImag} />

			<section id="home-header" className="section-full bg-cover">
				<div className="section-inner">
					<div className="d-flex flex-column gap-3 align-items-center text-center">
						<p className="small">{t('home.subtitle')}</p>
						<h1 className="display-1">
							<TextUnderline text={t('home.title')} />
						</h1>
						<Link to={getLink('company')}>{t('common.how')}</Link>
					</div>
				</div>
			</section>
			<Company />
			<Services />
			<Solutions />
			<Partnership />
		</>
	);
};

export default Home;
