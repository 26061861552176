import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Waypoint } from 'react-waypoint';

import AppContext from '../helpers/AppContext';

import MetaTags from '../components/extra/MetaTags';
import TextUnderline from '../components/extra/TextUnderline';

import Content from '../components/solutions/Content';
import CaseStudy from '../components/solutions/CaseStudy';

import composer from '../img/solutions/composer.mp4';
import metaImg from '../img/solutions/composer.jpg';

const Composer = () => {
	const { t } = useTranslation();
	const [playState, updatePlayState] = useState(false);
	const { setCurrentPage } = useContext(AppContext);

	useEffect(() => {
		setCurrentPage('composer');
	}, []);

	return (
		<>
			<MetaTags title={t('composer.title')} desc={t('composer.metaDesc')} img={metaImg} />

			<section className="section-content section-solution">
				<Container flud="lg" className="section-inner">
					<Row className="g-md-5 d-flex align-items-center">
						<Col xs="12" md="6" className="d-flex flex-column">
							<div className="solution-item">
								<div className="composer solution-bg" />
								<div className="composer solution-image-box">
									<Waypoint onEnter={() => updatePlayState(true)} onLeave={() => updatePlayState(false)}>
										<div className="browser">
											<div className="video-wrapper bg-cover">
												<ReactPlayer
													playing={playState}
													url={composer}
													muted
													loop
													playsinline
													width="100%"
													height="100%"
												/>
											</div>
										</div>
									</Waypoint>
								</div>
							</div>
						</Col>
						<Col xs="12" md="6" className="d-flex flex-column">
							<h1 className="display-1">
								<TextUnderline text={t('composer.title')} />
							</h1>
							<p>{t('composer.metaDesc')}</p>
						</Col>
					</Row>
				</Container>
			</section>

			<Content what="composer" />
			<CaseStudy
				cases={[
					{ key: 'gibam', text: 'Gibam Composit', link: 'https://www.gibamcomposit.com' },
					{ key: 'graniti', text: 'Graniti Vicentia', link: 'https://www.gvfacades.net' }
				]}
			/>
		</>
	);
};

export default Composer;
