/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Text = () => (
	<>
		<p>
			La presente Politica sulla privacy ("Informativa") descrive il trattamento dei dati personali da parte di MEBlabs
			SRL, P.IVA/C.F. 02619960418 (di seguito la Società) che qui tratta i vostri dati attraverso il sito
			www.meblabs.com. Ai sensi della legislazione sulla protezione dei dati applicabile (compreso il Regolamento
			generale sulla protezione dei dati 2016/679 (il "GDPR")), la Società è il Titolare del trattamento dei dati
			personali, cioè il soggetto che decide come e perché trattare i tuoi dati personali.
		</p>
		<p>
			L’informativa è resa solo per il sito in oggetto. Le informazioni che seguono non si applicano a prodotti o
			servizi non forniti direttamente dalla Società.
		</p>
		<p>
			La Società riconosce l’importanza della protezione dei dati personali e considera la loro tutela uno degli
			obiettivi principali della propria attività.
		</p>
		<p>
			Per questo, ai sensi degli Art. 13/14 del Regolamento Europeo 679/2016 relativo alla protezione ed al trattamento
			dei dati personali, nonché alla libera circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento
			generale della protezione dei dati), ti invitiamo a leggere con attenzione la seguente informativa poiché la
			stessa contiene informazioni importanti sulla tutela dei dati personali e sulle misure di sicurezza adottate per
			garantire la riservatezza dei tuoi dati.
		</p>
		<br />
		<p>
			<b>Dati e contatti del Titolare del Trattamento</b>
		</p>
		<p>
			Il titolare del trattamento è la Società MEBlabs SRL - P.IVA/C.F. 02619960418 con sede in Via Monte Catria, 6 -
			61032 Fano (PU).
		</p>
		<p>
			Puoi sempre contattare la Società scrivendo all’indirizzo <a href="mailto:staff@meblabs.com">staff@meblabs.com</a>
		</p>
		<br />
		<p>
			<b>Principi applicati al Trattamento</b>
		</p>
		<p>
			Sulla base dell’Art. 5 Principi applicabili al trattamento di dati personali i dati vengono trattati in modo
			lecito, corretto e trasparente nei confronti dell’interessato («liceità, correttezza e trasparenza»); raccolti per
			finalità determinate, esplicite e legittime, adeguati, pertinenti e limitati a quanto necessario rispetto alle
			finalità per le quali sono trattati («minimizzazione dei dati»).
		</p>
		<br />
		<p>
			<b>Finalità del trattamento</b>
		</p>
		<p>
			Il trattamento dei dati raccolti dal sito, oltre alle finalità connesse, strumentali e necessarie alla fornitura
			del servizio, è volto alle seguenti finalità:
		</p>
		<ul>
			<li>
				Sicurezza:
				<br />
				Raccolta di dati e informazioni al fine di tutelare la sicurezza del sito (filtri antispam, firewall,
				rilevazione virus) e degli utenti e per prevenire o smascherare frodi o abusi a danno del sito web. I dati sono
				registrati automaticamente e possono eventualmente comprendere anche dati personali (indirizzo Ip) che
				potrebbero essere utilizzati, conformemente alle leggi vigenti in materia, al fine di bloccare tentativi di
				danneggiamento al sito medesimo o di recare danno ad altri utenti, o comunque attività dannose o costituenti
				reato. Tali dati non sono mai utilizzati per l'identificazione o la profilazione dell'utente e vengono
				cancellati periodicamente.
			</li>
			<li>
				Attività accessorie:
				<br />
				Comunicare i dati a terze parti che svolgono funzioni necessarie o strumentali all'operatività del servizio e
				per consentire a terze parti di svolgere attività tecniche e di altro tipo per nostro conto.
			</li>
		</ul>
		<br />
		<p>
			<b>Luogo di Trattamento dei Dati</b>
		</p>
		<p>
			I dati raccolti dal sito sono trattati presso la sede del Titolare del Trattamento e presso soggetti terzi
			individuati.
		</p>
		<br />
		<h3>Dati personali trattati</h3>
		<p>
			<b>1. Dati di navigazione:</b>
		</p>
		<p>
			I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso
			del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di
			comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati
			identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti
			da terzi, permettere di identificare gli utenti.
		</p>
		<p>
			In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che
			si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste,
			l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file
			ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore,
			ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
		</p>
		<p>
			Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per
			controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati potrebbero
			essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni del sito:
			salva questa eventualità, allo stato i dati sui contatti web non persistono per più di sette giorni.
		</p>
		<br />
		<p>
			<b>2. Dati forniti volontariamente dall’utente:</b>
		</p>
		<p>
			L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta
			la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli
			eventuali altri dati personali inseriti nella missiva. Nello specifico la Società può trattare dati personali come
			dati anagrafici nome e cognome, indirizzo mail. I dati vengono trattati per dare seguito e risposta alle richieste
			e per fornire informazioni.
		</p>
		<br />
		<p>
			<b>Cookies:</b>
		</p>
		<p>
			Il sito www.meblabs.com utilizza solo cookies tecnici necessari per navigare all’interno di esso che consentono
			funzioni essenziali quali la gestione di una sessione di navigazione.{' '}
		</p>
		<br />
		<p>
			<i>Cookie di funzionalità</i>
		</p>
		<p>
			I cookie consentono al sito di ricordare le scelte fatte dall’utente (come il nome, la lingua o la regione di
			provenienza) e forniscono funzionalità avanzate personalizzate. Le informazioni raccolte da questi tipi di cookie
			possono essere rese anonime e non in grado di monitorare la vostra attività di navigazione su altri siti web.
		</p>
		<p>
			Utilizzando il nostro sito, l’utente accetta che tali cookie possono essere installati sul proprio dispositivo.
		</p>
		<p>
			Oltre a quelli elencati, i cookie possono essere anche di terze parti. Questi ultimi ricadono sotto la diretta ed
			esclusiva responsabilità dello stesso gestore.
		</p>
		<br />
		<p>
			Questo sito web utilizza Google Analytics con IP anonimizzato. Per informazioni relative alla privacy di Google{' '}
			<a href="https://support.google.com/analytics/answer/6004245?hl=it">
				https://support.google.com/analytics/answer/6004245?hl=it
			</a>
			.
		</p>
		<br />
		<p>
			<b>Basi giuridiche del trattamento</b>
		</p>
		<p>
			Trattiamo i tuoi dati personali soltanto in presenza di una delle condizioni previste dalla normativa vigente.
		</p>
		<p>
			Trattiamo i tuoi dati prevalentemente in base al consenso. Il conferimento del consenso avviene tramite il banner
			posto nella home page del sito, oppure tramite l’uso o la consultazione del sito, quale comportamento concludente.
			Con l'uso o la consultazione del sito i visitatori e gli utenti approvano la presente informativa privacy e
			acconsentono al trattamento dei loro dati personali.
		</p>
		<p>
			Trattiamo i tuoi dati in base al consenso anche quando utilizzando il nostro form dei contatti accetti la nostra
			privacy policy. Il conferimento dei dati e quindi il consenso alla raccolta e al trattamento dei dati è
			facoltativo, l'Utente può negare il consenso. Tuttavia negare il consenso può comportare l'impossibilità di
			erogare alcuni servizi e l'esperienza di navigazione nel sito sarebbe compromessa.{' '}
		</p>
		<br />
		<p>
			<b>Trasferimento dati extra-EU</b>
		</p>
		<p>
			Non sono previsti trasferimenti verso paesi Extra UE. Nel caso, il trasferimento dei tuoi dati personali verso
			paesi che non appartengono all'Unione Europea e che non assicurino livelli di tutela adeguati saranno eseguiti
			solo previa conclusione tra la Società e la terza parte destinataria dei dati di specifici accordi, contenenti
			clausole di salvaguardia e garanzie appropriate per la protezione dei dati personali.
		</p>
		<br />
		<p>
			<b>Trasferimento dei dati raccolti a terze parti</b>
		</p>
		<p>
			I dati rilevati dal sito generalmente non vengono forniti a terzi, tranne che in casi specifici: legittima
			richiesta da parte dell’autorità giudiziaria e nei soli casi previsti dalla legge; qualora sia necessario per la
			fornitura di uno specifico servizio richiesto dell'Utente; per l'esecuzione di controlli di sicurezza o di
			ottimizzazione del sito.{' '}
		</p>
		<br />
		<p>
			<b>Periodo di conservazione dei dati</b>
		</p>
		<p>
			I dati raccolti dal sito durante il suo funzionamento sono conservati per il tempo strettamente necessario a
			svolgere le attività precisate. Alla scadenza i dati saranno cancellati, a meno che non sussistano ulteriori
			finalità per la conservazione degli stessi. Il dato dell’indirizzo IP utilizzato a fini di sicurezza del sito
			(blocco tentativi di danneggiare il sito) è conservato all’interno del sistema per il tempo utile al
			raggiungimento della finalità per il quale è stato raccolto.
		</p>
		<br />
		<p>
			<b>Modalità del Trattamento</b>
		</p>
		<p>
			I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli
			scopi per cui sono stati raccolti.
		</p>
		<p>
			Il trattamento dei dati avverrà per mezzo di strumenti e/o modalità volte ad assicurare la riservatezza e la
			sicurezza dei dati e per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati,
			nel rispetto di quanto definito dalle norme delle disposizioni vigenti, ivi compreso il Regolamento Europeo
			679/2016.
		</p>
		<br />
		<p>
			<b>Diritti degli Interessati</b>
		</p>
		<p>
			Ai sensi del Regolamento europeo 679/2016 (GDPR) l'Utente può, secondo le modalità e nei limiti previsti dalla
			vigente normativa, esercitare i seguenti diritti:
		</p>
		<ol>
			<li>opporsi in tutto o in parte, per motivi legittimi al trattamento dei dati personali che lo riguardano; </li>
			<li>richiedere la conferma dell'esistenza di dati personali che lo riguardano (diritto di accesso);</li>
			<li>conoscerne l'origine;</li>
			<li>riceverne comunicazione intelligibile;</li>
			<li>avere informazioni circa la logica, le modalità e le finalità del trattamento;</li>
			<li>
				richiederne l'aggiornamento, la rettifica, l'integrazione, la cancellazione, la trasformazione in forma anonima,
				il blocco dei dati trattati in violazione di legge, ivi compresi quelli non più necessari al perseguimento degli
				scopi per i quali sono stati raccolti;
			</li>
			<li>
				nei casi di trattamento basato su consenso, ricevere al solo costo dell’eventuale supporto, i suoi dati forniti
				al titolare, in forma strutturata e leggibile da un elaboratore di dati e in un formato comunemente usato da un
				dispositivo elettronico;
			</li>
			<li>
				il diritto di presentare un reclamo all’Autorità di controllo (Garante Privacy – link alla pagina del Garante);{' '}
			</li>
			<li>
				nonché, più in generale, esercitare tutti i diritti che gli sono riconosciuti dalle vigenti disposizioni di
				legge.
			</li>
		</ol>
		<p>
			Le richieste vanno rivolte al Titolare del trattamento all’indirizzo di posta elettronica staff@meblabs.com. Per
			garantire che i dati dei nostri utenti non subiscano violazioni o utilizzi illegittimi da parte di terzi, prima di
			accogliere una tua richiesta di esercizio di uno dei diritti indicati, ti chiederemo alcune informazioni per
			essere certi della tua identità.
		</p>
		<br />
		<p>
			<b>Reclami</b>
		</p>
		<p>
			Se ritieni che il trattamento dei tuoi dati personali sia stato svolto illegittimamente, puoi proporre un reclamo
			a una delle autorità di controllo competenti per il rispetto delle norme in materia di protezione dei dati
			personali. In Italia, il reclamo può essere presentato al Garante per la Protezione dei Dati Personali.
		</p>
		<p>
			Maggiori informazioni sulle modalità di presentazione sono disponibili sul sito del Garante, all’indirizzo{' '}
			<a href="http://www.garanteprivacy.it">http://www.garanteprivacy.it</a>.
		</p>
		<br />
		<p>
			<b>Modifiche alla presente informativa</b>
		</p>
		<p>
			La costante evoluzione dei nostri servizi può comportare modifiche nelle caratteristiche del trattamento dei tuoi
			dati personali fin qui descritto. Di conseguenza questa informativa privacy potrà subire modifiche e integrazioni
			nel corso del tempo, le quali potrebbero anche essere necessarie in riferimento a nuovi interventi normativi in
			materia di protezione dei dati personali.
		</p>
		<p>
			Ti invitiamo, pertanto, a verificarne periodicamente i contenuti: laddove possibile, cercheremo di informarti
			tempestivamente sulle modifiche apportate e sulle loro conseguenze.
		</p>
		<br />
		<p>
			<b>Riferimenti legislativi e link utili</b>
		</p>
		<p>
			Il trattamento dei tuoi dati personali viene svolto da MEBlabs SRL nel pieno rispetto della disciplina in materia
			prevista dal Regolamento (UE) 2016/679 regolamento generale sulla protezione dei dati, delle norme in materia di
			trattamento dei dati personali italiane e dei provvedimenti della Autorità di controllo italiana (
			<a href="http://www.garanteprivacy.it">http://www.garanteprivacy.it</a>).
		</p>
	</>
);

export default Text;
