import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Container, Form, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsQuestionCheck, faThumbsUp } from '@fortawesome/pro-duotone-svg-icons';
import strReplace from 'react-string-replace';

import AppContext from '../helpers/AppContext';

const Footer = () => {
	const { t } = useTranslation();
	const { getLink } = useContext(AppContext);
	const location = useLocation();

	const [sending, setSending] = useState(0);
	const [validated, setValidated] = useState(false);

	const handleSubmit = event => {
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;
		setValidated(true);

		if (form.checkValidity() === false) return false;

		const formData = new FormData(form);
		const formDataObj = Object.fromEntries(formData.entries());
		setSending(1);

		return axios
			.post('https://contacts.meblabs.com/request.php', {
				name: formDataObj.name,
				email: formDataObj.email,
				text: formDataObj.text
			})
			.then(response => setSending(2))
			.catch(error => setSending(0));
	};

	useEffect(() => {
		setValidated(false);
	}, [location]);

	const privacyText = strReplace(t('contacts.privacy'), '%s', (match, i) => (
		<Link to={getLink('privacy')} key={i}>
			{t('footer.privacy')}
		</Link>
	));

	return (
		<section id="footer" className="bg-cover">
			<Container id="contact-us" className="d-flex align-items-flex-start align-content-stretch flex-column">
				<div className="flex-grow-1 d-flex align-items-center">
					<div className="footer-contacts">
						<FontAwesomeIcon icon={faCommentsQuestionCheck} className="duo-primary-swap fa-beat display-1" />
						<h1 className="display-2">{t('footer.title')}</h1>
						<h3 className="text-primary">{t('footer.text')}</h3>

						{sending === 2 ? (
							<Alert cvariant="success" className="text-center mt-4">
								<FontAwesomeIcon icon={faThumbsUp} className="duo-primary display-4 mb-3" />
								<p className="mb-0">{t('contacts.sendOk')}</p>
							</Alert>
						) : (
							<Form className="contacts-form" onSubmit={handleSubmit} noValidate validated={validated}>
								<Form.Group className="mb-3" controlId="validationName">
									<Form.Control type="name" name="name" placeholder={t('contacts.name')} required disabled={sending} />
								</Form.Group>
								<Form.Group className="mb-3" controlId="validationEmail">
									<Form.Control
										type="email"
										name="email"
										placeholder={t('contacts.email')}
										required
										disabled={sending}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="validationText">
									<Form.Control
										as="textarea"
										rows={3}
										name="text"
										placeholder={t('contacts.messagge')}
										required
										disabled={sending}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="validationPrivacy">
									<Form.Check
										type="checkbox"
										name="privacy"
										label={privacyText}
										required
										feedback={t('contacts.privacyError')}
										feedbackType="invalid"
										disabled={sending}
									/>
								</Form.Group>
								<div className="text-center">
									<Button variant="primary" type="submit" disabled={sending}>
										{sending === 1 && (
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
												className="me-2"
											/>
										)}
										{t('contacts.btn')}
									</Button>
								</div>
							</Form>
						)}
					</div>
				</div>
				<div className="footer-copy d-flex align-self-stretch justify-content-between">
					<p>&copy; {new Date().getFullYear()} MEBlabs S.r.l - All Rights Reserved</p>
					<p>
						<Link to={getLink('privacy')}>{t('footer.privacy')}</Link>
					</p>
				</div>
			</Container>
		</section>
	);
};

export default Footer;
