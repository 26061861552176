import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTelescope,
	faLightbulbExclamationOn,
	faTimelineArrow,
	faBullseyeArrow,
	faCircleCheck
} from '@fortawesome/pro-duotone-svg-icons';

import TextUnderline from '../extra/TextUnderline';
import AppContext from '../../helpers/AppContext';

const Partner = () => {
	const { t } = useTranslation();
	const { getLink } = useContext(AppContext);

	return (
		<section className="section-content dark">
			<Container flud="lg" className="section-inner no-bottom">
				<div className="company-steps">
					<div id="company-step-1" className="step-item bg-cover">
						<div className="step-item-inner d-flex flex-column align-items-center justify-content-center text-center">
							<FontAwesomeIcon icon={faTelescope} className="duo-primary-swap" />
							<h3>{t('company.step1')}</h3>
						</div>
					</div>
					<div id="company-step-2" className="step-item bg-cover">
						<div className="step-item-inner d-flex flex-column align-items-center justify-content-center text-center">
							<FontAwesomeIcon icon={faLightbulbExclamationOn} className="duo-primary-swap" />
							<h3>{t('company.step2')}</h3>
						</div>
					</div>
					<div id="company-step-3" className="step-item bg-cover">
						<div className="step-item-inner d-flex flex-column align-items-center justify-content-center text-center">
							<FontAwesomeIcon icon={faTimelineArrow} className="duo-primary-swap" />
							<h3>{t('company.step3')}</h3>
						</div>
					</div>
					<div id="company-step-4" className="step-item bg-cover">
						<div className="step-item-inner d-flex flex-column align-items-center justify-content-center text-center">
							<FontAwesomeIcon icon={faBullseyeArrow} className="duo-primary-swap" />
							<h3>{t('company.step4')}</h3>
						</div>
					</div>
				</div>
			</Container>
			<Container fluid="lg" className="section-inner sm no-bottom">
				<Row className="justify-content-center g-5">
					<Col xs="12">
						<p className="fs-4">{t('company.text1')}</p>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column gap-2">
						<h3>
							1//
							<br />
							<TextUnderline text={t('company.goal1')} />
						</h3>
						<ul className="list-unstyled">
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t('company.goal1_t1')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t('company.goal1_t2')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t('company.goal1_t3')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t('company.goal1_t4')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t('company.goal1_t5')}</span>
							</li>
						</ul>
					</Col>
					<Col xs="12" md="6" className="d-flex flex-column gap-2">
						<h3>
							2//
							<br />
							<TextUnderline text={t('company.goal2')} />
						</h3>
						<p>{t('company.goal2_t1')}</p>
					</Col>
				</Row>
			</Container>
			<div id="company-image1" className="content-image bg-cover">
				<div className="gradient" />
				<div className="content-image-text">
					<Container fluid="lg" className="section-inner sm">
						<p className="fs-4">{t('company.text2')}</p>
						<HashLink to={getLink() + '#services'}>{t('company.servicesLink')}</HashLink>
					</Container>
				</div>
			</div>
		</section>
	);
};

export default Partner;
