import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';

import arrowDx from '../../img/solutions/arrow-dx.svg';
import arrowSx from '../../img/solutions/arrow-sx.svg';

const Content = ({ what }) => {
	const { t } = useTranslation();

	return (
		<section className="section-content">
			<Container flud="lg" className="section-inner">
				<Row className="d-flex align-items-center mb-5">
					<Col xs="12" md="6" className="d-flex flex-column order-xs-0 order-md-0">
						<h2 className="bordered-title">{t('solutions.tit1')}</h2>
						<p>
							<Trans i18nKey={what + '.text1'} />
						</p>
					</Col>
					<Col xs="12" md="6" className="order-xs-1 order-md-1">
						<div className="solution-arrow dx">
							<img src={arrowDx} alt="" />
						</div>
					</Col>
				</Row>
				<Row className="d-flex align-items-center mb-5">
					<Col xs="12" md="6" className="d-flex flex-column order-xs-0 order-md-1">
						<h2 className="bordered-title">{t('solutions.tit2')}</h2>
						<p>
							<Trans i18nKey={what + '.text2'} />
						</p>
					</Col>
					<Col xs="12" md="6" className="order-xs-1 order-md-0">
						<div className="solution-arrow sx">
							<img src={arrowSx} alt="" />
						</div>
					</Col>
				</Row>
				<Row className="d-flex align-items-center mb-5">
					<Col xs="12" md="6" className="d-flex flex-column order-xs-0 order-md-0">
						<h2 className="bordered-title">{t('solutions.tit3')}</h2>
						<p>
							<Trans i18nKey={what + '.text3'} />
						</p>
						<ul className="list-unstyled">
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t(what + '.text3_f1')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t(what + '.text3_f2')}</span>
							</li>
							<li className="check-list">
								<FontAwesomeIcon icon={faCircleCheck} className="duo-primary-list" />
								<span>{t(what + '.text3_f3')}</span>
							</li>
						</ul>
					</Col>
					<Col xs="12" md="6" className="order-xs-1 order-md-1">
						<div className="solution-arrow dx">
							<img src={arrowDx} alt="" />
						</div>
					</Col>
				</Row>
				<Row className="d-flex align-items-center">
					<Col xs="12" md="6" className="d-flex flex-column order-xs-0 order-md-1">
						<h2 className="bordered-title">{t('solutions.tit4')}</h2>
						<p>
							<Trans i18nKey={what + '.text4'} />
						</p>
					</Col>
					<Col xs="12" md="6" className="order-xs-1 order-md-0">
						<div id={'solution-image-' + what} className="solutions-image bg-cover" />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Content;
